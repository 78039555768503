table > thead > tr > th {
    background-color: #1890ff !important;
    color: #fff !important;
}
.ant-page-header-heading-title{
    color: #001529 !important;
}

.tablaProductos::-webkit-scrollbar {
    
    background: #ffffff !important;
}
.tablaProductos::-webkit-scrollbar-thumb {
    background: #001529;
    border-radius: 10px;
    width: 30px !important;
}