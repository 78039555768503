#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
  
.buttonUpToHead{
  height: 40px;
  width: 40;
  line-height: 40px;
  border-radius: 50%;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 14;
  box-shadow: 0 2px 20px #1088e9;
  transform: scale(1.3);
}