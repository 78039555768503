.leftLogin{
    height: 100vh;
    max-width: 50vw;
    box-shadow: 1px 0px 5px 1px rgba(107,107,107,0.3);
}
.logoImg{
    width: 100%;
    height: 100%;
}
.rightLogin{
    height: 100vh;
}
