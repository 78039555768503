    .steps-content {
        min-height: 200px;
        padding-top: 20px;
        border-radius: 2px;
    }

    .steps-action {
        margin-top: 24px;
    }

    .pasosSteper:hover{
        cursor: pointer;
    }

    label.ant-radio-button-wrapper::before{
        display: none !important;
        
    }

    label.ant-radio-button-wrapper-checked{
        transform: scale(1.4);
        border-color: none !important;
    }

    label.ant-radio-button-wrapper:hover{
        border-color: none !important;
    }
    label.ant-radio-button-wrapper{
        border-color: none !important;
    }


    .cardNoDetails{
        max-width: 50vw;
        border-radius: 15;
    }

    .filasProductos{
        user-select: none !important;
    }
    .filasProductos:hover{
        cursor: pointer;
    }