.content-user-login{
    width: 60%;
    background-color: #fff;
    position: absolute;
    left: 20%;
}
.login-title{
    width: 100%;
    font-size: 34px;
    font-weight: bold;
    font-family: 'Arial';
    color: #273979;
    margin-bottom: 20px;
    text-align: center;
}

label{
	width: 100%;
    font-size: 16px;
	font-weight: bold;
	color: #929292;
    text-align: center;
}
