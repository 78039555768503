@import 'antd/dist/antd.css';

.ant-layout {
    min-height: 100vh;
}

.ant-modal-content{
    border-radius: 15px !important;
}

.ant-modal-header{
    border-radius: 15px 15px 0 0 !important;
}

.ant-radio-button-wrapper{
    border: none !important;
}
.ant-notification {
    z-index: 9999 !important;
}

/* .ant-select-selector {
    background: red !important;
    color: #000;
  } */
